<template>
  <div class="course-choosing" :style="subTypeCourseList.length > 0 ? 'padding-bottom: 80px;' : 'padding-bottom:0px;'">
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>


    <!-- 面包屑 -->
    <div style="width: 100%;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程选择</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- 课程详情 -->
    <div v-if="CourseChoosing" class="detail-back">
      <div class="detail">
        <div class="detail-top">
          <div class="detail-left">
            <div class="detail-img">
              <img v-if="CourseChoosing.bigCover" :src="$store.getters.vodDomainName + CourseChoosing.bigCover" alt="">
              <img v-if="!CourseChoosing.bigCover" src="@/assets/img/course.jpg" alt="#">
            </div>
            <!-- <div class="course" @click="auditionCourse">试听课程</div> -->
          </div>
          <div class="detail-right">
            <div class="title">{{ CourseChoosing.name }}</div>
            <!-- <div class="person">课程讲师：{{ CourseChoosing.teacherName }}</div> -->
            <div class="details">
              <div class="details-div">
                <div class="sub-title">学习人数</div>
                <div class="sub-content">{{ CourseChoosing.hits == null ? 0 : CourseChoosing.hits }}人</div>
              </div>
              <div class="line"></div>
              <div class="details-div">
                <div class="sub-title">课程学时</div>
                <div class="sub-content">共{{ CourseChoosing.creditHours }}学时</div>
              </div>
              <div class="line"></div>
              <div class="details-div">
                <div class="sub-title">课程讲师</div>
                <div class="sub-content">{{ CourseChoosing.courseTeacher.name }}</div>
              </div>
              <!-- <div class="line"></div>
              <div class="details-div">
                <div class="sub-title">讲师介绍</div>
                <div class="sub-content">{{CourseChoosing.teacherIntro}}</div>
              </div> -->
            </div>
            <div class="do">
              <div class="type"> ￥{{ CourseChoosing.certificatePrice }} </div>
              <div class="join" @click="joinCourse" v-if="!CourseChoosing.isJoin">加入课程</div>
              <div class="join" @click="gotoCourse" v-if="CourseChoosing.isJoin">进入课程</div>
            </div>
          </div>
        </div>
        <div class="detail-bottom">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程介绍" name="first">
              <div class="title">
                课程介绍
              </div>
              <div class="content" v-html="CourseChoosing.details">
              </div>
            </el-tab-pane>
            <el-tab-pane label="课程目录" name="second">
              <el-collapse v-if="CourseChoosing.courseChapterDTOList">
                <el-collapse-item  v-for="(item, i) in CourseChoosing.courseChapterDTOList" :key="i"
                  :title="i + 1 + '.' + item.name" :name="i">
                  <div v-for="(item, index) in item.sectionDTOList" :key="index"
                    style="padding: 5px 10px 5px 10px;display: flex;justify-content: space-between;">
                    <div>
                      <span style="margin-right: 5px;">第{{ item.num }}节</span>
                      <span>{{ item.name }}</span>
                    </div>
                    <div>
                      <span class="time">{{ parseInt(item.time) > 60 ?
                        parseInt(item.time / 60) + "分钟" : parseInt(item.time) + "秒" }}</span>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div>
        <div class="footer">
            <div class="footer-detail">
                <div style="width: 82.7%;">
                    <img class="code-logo" :src="$store.getters.enterpriseObj.config.homeLogo" alt="">
                    <div style="margin-top: 10px;margin-bottom: 10px;">
                        <span>主办单位：{{ $store.getters.enterpriseObj.name }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>地址： {{ $store.getters.enterpriseObj.address }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>联系电话： {{ $store.getters.enterpriseObj.mobile }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottom-content">
          <div>Copyright 2019 • 苏州市电子信息技师学院</div>
          <div class="bottom-right">
            <a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备11034322号-2</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户选择的课时 -->
    <div class="certificates" v-if="this.$store.getters.oatoken">
      <div style="display: flex;line-height: 40px;text-align: center;">
        <!-- <div v-for="index in subTypeCourseList"> -->
        <div>
          <!-- <span style="font-size: 20px;color: #1e90ff;">{{ index }}</span> -->
          <span>
            总计：已选择<span style="font-size: 20px;color: #1e90ff;font-weight: bold;"> {{ userMsg["全部"].selected }} </span>门课程
          </span>
          <span style="margin-left: 30px;">
            学时：含有
            <span v-if="this.totalHoursSelect == '公共课14学时'" style="font-size: 20px;font-weight: bold;" :style="userMsg['全部'].totalHours==14?'color: #1e90ff;':'color: red;'">
              {{ userMsg["全部"].totalHours }}
            </span>
            <span v-if="this.totalHoursSelect == '公共课24学时'" style="font-size: 20px;font-weight: bold;"
            :style="userMsg['全部'].totalHours==24?'color: #1e90ff;':'color: red;'">
              {{ userMsg["全部"].totalHours }}
            </span>
            / {{ this.totalHoursSelect == "公共课14学时" ? "14" : "24" }} 学时
          </span>
        </div>
        <!-- </div> -->
        <!-- <div style="flex: 0.4;" v-if="userMsg['全部'].totalHours >= 14 && userMsg['全部'].totalHours < 24">
          <span style="color: #1e90ff;">已满足公共课14学时要求</span>
        </div> -->
        <div style="width: 200px;">
          <el-select v-model="totalHoursSelect">
            <el-option label="公共课14学时" value="公共课14学时">
            </el-option>
            <el-option label="公共课24学时" value="公共课24学时">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import topNav from "@/components/nav/nav.vue"
import { httpGet, httpPost, httpNPPost, httpNPGet } from "@/utils/httpRequest";
import { info } from "console";

export default {
  name: "CourseChoosing",
  data() {
    return {
      subTypeCourseList: [],
      // 
      pageIndex: 1,
      // nav
      active: 0,
      translateX: 700,
      lineWidth: 32,
      moveFlag: false,
      //  课程详情
      activeName: 'first',
      // 课程具体数据
      CourseChoosing: {courseTeacher:{}},
      courseMenu: [],
      totalHoursSelect: "公共课14学时",
      userMsg: {"全部": {}},
    }
  },
  components: {
    topNav
  },
  created() {
  },
  mounted() {
    this.getCrouseData()
    if (localStorage.getItem('oatoken')) {
      this.GetUserMsg();
    }
  },
  methods: {
    // 获取用户学习信息数
    GetUserMsg() {
      this.subTypeCourseList = []
      httpNPGet("/app/app-user-credit-hours/detail",
        (res) => {
          this.userMsg = res.data
          if (this.userMsg["全部"].totalHours >= 24) {
            this.totalHoursSelect = "公共课24学时"
          }
          this.subTypeCourseList.push("全部");
          for (const key in res.data) {
            // console.log(key);
            if (key !== "全部") {
              this.subTypeCourseList.push(key);
            }
          }
        }, (err) => {
          // // console.log(err)
        })
    },
    // 获取当前视频信息
    getCrouseData() {
      httpGet("/course-info/msg",
        {
          courseId: this.$route.query.id,
        }, (res) => {
          // console.log(res)
          this.CourseChoosing = res.data
        }, (err) => {
          // // console.log(err)
        })
    },
    // 试听课程
    auditionCourse() {
      // console.log(this.$route.params.studyId)
      this.$router.push({
        path: "/videocatalog",
        query: {
          courseName: this.CourseChoosing.name,
          courseId: this.$route.query.id,
          videoType: "试听"
        }
      })
    },
    // 进入课程
    gotoCourse() {
      // console.log(this.$route.query)
      this.$router.push({
        path: "/videocatalog",
        query: {
          courseName: this.$route.query.name,
          courseId: this.$route.query.id,
        }
      })
    },
    // 请求课程信息
    getCourseChoosingData() {
      httpGet("/course-info/detail",
        {
          infoId: this.$route.query.id
        }, (res) => {
          this.CourseChoosing = res.data
          // console.log(res.data)
          this.getCourseChoosingList()
        }, (err) => {
          // // console.log(err)
          throw err
        })
    },
    // 请求课程章节列表
    getCourseChoosingList() {
      httpGet("/course-chapter/list",
        {
          courseId: this.CourseChoosing.id
        }, (res) => {
          // console.log(res.data)
          this.courseMenu = res.data
        }, (err) => {
          // // console.log(err)
          throw err
        })
    },

    // 加入课程
    joinCourse() {
      let data = this.$route.query.data
      if (this.totalHoursSelect == "公共课24学时") {
        if (24 < data.creditHours + this.userMsg["全部"].totalHours) {
          // this.$message("已满足公共课24学时")
          this.$alert('添加该课程后已选学时将超出24学时，是否继续？', '学时超出', {
            showCancelButton: true,
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            callback: action => {
              if (action == "confirm") {
                this.defineJoinCourse(data)
              }
            }
          });
        }else {
          this.defineJoinCourse(data)
        }
      } else if (this.totalHoursSelect == "公共课14学时") {
        // console.log("公共课14学时")
        if (14 < data.creditHours + this.userMsg["全部"].totalHours) {
          this.$alert('添加该课程后已选学时将超出14学时，是否继续？', '学时超出', {
            showCancelButton: true,
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            callback: action => {
              if (action == "confirm") {
                this.defineJoinCourse(data)
              }
            }
          });
        }else{
          this.defineJoinCourse(data)
        }
      } else {
        // console.log("defineJoinCourse")
        this.defineJoinCourse(data)
      }
    },
    defineJoinCourse(data) {
      if (localStorage.getItem("userId")) {
        httpNPPost("/app/app-user-course-study/add?courseId=" + data.id,
          (res) => {
            this.$message({
              message: '加入课程成功',
              type: 'success'
            });
            this.userMsg["全部"].selected++
            this.userMsg['全部'].totalHours = this.CourseChoosing.creditHours + this.userMsg['全部'].totalHours
            this.CourseChoosing.isJoin = true
            // this.getCourses();
            this.GetUserMsg();
            // location.reload()
          },
          (err) => {
            if (err.code == 500) {
              if (err.msg == "课程记录已添加") {
                // throw err
                this.$message({
                  message: '已加入该课程，请不要重复添加',
                  type: 'error'
                });
              } else if (err.msg == "token异常，请重新登录") {
                localStorage.clear();
                if (!localStorage.getItem("userId")) {
                  this.$message.error('登录过期，请重新登录!')
                  this.$router.push("/login")
                }
              }
            }
            // console.log(err.msg)
          })
      }
    },
    // 课程详情tab
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 分类展开收缩
    clickTypeMove() {
      // 获取展开ref
      let list = this.$refs.move;
      // 获取收缩状态时的高度值
      let height = list.offsetHeight;
      if (!this.moveFlag) {
        // 展开样式
        list.style.height = 'auto';
        height = list.offsetHeight;
        list.style.height = 32 + 'px';
        // 计算高度
        document.body.offsetHeight;
        list.style.height = (height - 20) + 'px';
      } else {
        // 收缩样式
        list.style.height = 32 + 'px'
      }
      this.moveFlag = !this.moveFlag;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val
    },
    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index) {
      this.active = index;
      if (index === 1) {
        this.$router.push('CourseCenter')
      }
      if (index === 2) {
        this.$router.push('Helper')
      }
      if (index === 3) {
        this.$router.push('PersonalHome')
      }

      this.setUnderLine();
      this.setUnderLine();
    },
  }
}
</script>

<style lang="scss" scoped>
.course-choosing {
  background-color: rgb(247, 247, 247)
}

.detail-back {
  // background-color: #fff;
  // height: 29rem;
  //background-color: #E9F0F6;
  // margin-bottom: 18.75rem;

  .detail {
    width: 61.458%;
    margin: 0 auto;
    min-width: 1200px;

    .detail-top {
      display: flex;
      height: 15.875rem;
      padding: 1.25em;
      background-color: #fff;

      .detail-left {
        position: relative;
        //background-color: red;
        width: 38.305%;
        height: 100%;

        .detail-img {
          width: 100%;
          height: 100%;
          box-shadow: 0 0.8125rem 0.875rem -0.625rem rgba(20, 35, 85, 0.36);
          border-radius: 0.125rem 0.125rem 0.125rem 0.125rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .course {
          cursor: pointer;
          width: 68%;
          max-width: 161px;
          //height: 4rem;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 2rem 2rem 2rem 2rem;
          font-size: 1.0625rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 4rem;
          align-items: center;
          margin-top: -9.875rem;
          position: relative;
          display: flex;
          margin-left: auto;
          margin-right: auto;
        }

        .course::before {
          content: "";
          display: inline-block;
          width: 1.8125rem;
          height: 1.75rem;
          background: url('~@/assets/img/pre/icon-open.png');
          background-size: 100% 100%;
          margin-right: 0.5rem;
          margin-left: 16.5644%;
        }
      }

      .detail-right {
        margin-left: 2.71186%;
        text-align: left;
        width: 58.983%;

        .title {
          height: 4.25rem;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 700;
          color: #191b1e;
          line-height: 2.125rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .person {
          height: 1.25rem;
          font-size: 0.8125rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #586470;
          line-height: 1.25rem;
          margin: 1rem 0 0.8125rem 0;
        }

        .details {
          width: 66%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1.5rem;
          text-align: center;

          .line {
            width: 0.0625rem;
            height: 1.5625rem;
            background: #D9D9D9;
            opacity: 0.6;
          }

          .details-div {
            display: inline-block;
          }

          .sub-title {
            font-size: 0.8125rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666C80;
            line-height: 1.25rem;
          }

          .sub-content {
            font-size: 1.0625rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #14171A;
            line-height: 1.25rem;
          }
        }

        .do {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .type {
            font-size: 2rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #DC143C;
            line-height: 1.5rem;
            margin-top: 2.875rem;
            height: 1.5rem;
          }

          .join {
            font-size: 1.0625rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 3rem;
            //width: 23.45323%;
            //height: 3rem;
            padding: 0 47px;
            background: #1371E7;
            box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
            border-radius: 0.125rem 0.125rem 0.125rem 0.125rem;
            text-align: center;
            margin: 2.125rem 0 0 0;
            cursor: pointer;
          }
        }
      }
    }

    .detail-bottom {
      margin-top: 1.5rem;
      .title {
        height: 1.75rem;
        font-size: 1.25rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 1.4375rem;
        margin: 1.25rem 0;
      }

      .content {
        // height: 4.125rem;
        font-size: 0.9375rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;
        line-height: 1.375rem;
      }

      .menu-line {
        // display: flex;
        justify-content: space-between;
        height: 3.25rem;
        background: #F5F8FA;
        border-radius: 0.125rem 0.125rem 0.125rem 0.125rem;
        margin-bottom: 1.25rem;
        align-items: center;

        .menu {
          height: 1.375rem;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #3E454D;
          line-height: 1.375rem;

          .sign {
            margin: 0.9375rem 0.8125rem 0.9375rem 1rem;
          }
        }

        .time {
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #667280;
          line-height: 1.375rem;
          margin: 0.9375rem 1rem 0.9375rem 0;
        }
      }

      ::v-deep .el-tabs {
        background-color: #FFFFFF;
      }

      ::v-deep .el-tabs__nav-scroll {
        // width: 60% !important;
        // margin-left: 30%;
      }

      @media screen and (max-width: 1700px) {
        ::v-deep .el-tabs__nav-scroll {
          // margin-left: 26%;
        }
      }

      @media screen and (max-width: 1600px) {
        ::v-deep .el-tabs__nav-scroll {
          // margin-left: 25%;
        }
      }

      @media screen and (max-width: 1500px) {
        ::v-deep .el-tabs__nav-scroll {
          // margin: 0 24%;
        }
      }

      @media screen and (max-width: 1400px) {
        ::v-deep .el-tabs__nav-scroll {
          // margin: 0 23%;
        }
      }

      @media screen and (max-width: 1300px) {
        ::v-deep .el-tabs__nav-scroll {
          // margin: 0 20%;
        }
      }

      ::v-deep .el-tabs__nav-wrap::after {
        height: 0.0625rem;
        opacity: 0.8;
      }

      ::v-deep .el-tabs__nav {
        height: 4rem;
        line-height: 4rem;
        font-size: 0.9375rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #1371E7;
        height: 0.25rem;
      }

      ::v-deep .el-tabs__item.is-active {
        font-weight: bolder;
      }

      ::v-deep .el-tabs__item {
        height: 4rem;
        line-height: 4rem;
        font-size: 0.9375rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
        padding: 0 23.8%;
      }

      ::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
      }

      ::v-deep .el-tabs__content {
        min-height: 200px;
        margin: 40px 32px;
        //background-color: pink;
        overflow: hidden;
        overflow-y: scroll;
        text-align: left;

      }
    }
  }
}

.el-breadcrumb {
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #3E454D;
  line-height: 4rem;
  width: 61.458%;
  height: 4rem;
  margin: 0 auto 0 auto;
  min-width: 1200px;
  //background-color: #E9F0F6;
  //border-bottom: solid 0.0625rem #EDEFF2;
}

::v-deep .el-breadcrumb__inner.is-link {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
}

.bottom {
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/img/pre/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.footer {
  padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

  .footer-content {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 1.5rem auto;
    padding: 2rem 0 0 0;
    align-items: center;

    .footer-top-line-1 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
    }

    .footer-top-line-2 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      transform: rotate(180deg);
    }

    .footer-top-line-font {
      margin: 0 1.1%;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.47);
      line-height: 1.25rem;
      width: 70%;
    }

    @media screen and (max-width: 1539px) {
      .footer-top-line-font {
        width: 120%;
      }
    }

    @media screen and (max-width: 1400px) {
      .footer-top-line-font {
        width: 130%;
      }
    }
  }

  .footer-detail {
    width: 61.458%;
    //background-color: red;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    align-items: center;

    .line {
      width: 1px;
      height: 0.875rem;
      background: #FFFFFF;
      opacity: 0.2;
    }

    .code-logo {
      width: 35.87%;
    }

    .code-img {
      // width: 100%;
      height: 5.9375rem;
    }

    ul {
      list-style-type: none;
      margin-top: 1.75rem;
      display: flex;
      justify-content: space-between;

    }
  }
}

.nav {
  width: 100%;
  height: 5rem;
  background-color: rgba(203, 186, 186, 0.24);
}

.nav .nav-list {
  margin: 0 auto;
  max-width: 37.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4.75rem;
  /*background: #d40d0d;*/
}

.nav .nav-list>div {
  width: 100%;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 1rem;
  cursor: pointer;
  text-align: center;
  border-right: solid;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/

}

.nav .nav-list>div:last-child {
  border: none;
}

.nav .nav-list .nav-list--active {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #333333;

  /*transition: all 0.3s;*/
  /*background-color: #FFE53B;*/
  /*background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);*/
}

.nav-line {
  margin: 0 auto;
}

.nav-line>div {
  /*width: 32px;*/
  height: 0.25rem;
  background-color: #333333;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  border-radius: 0.125rem;
  transition-duration: 0.3s;
  position: relative;
  top: 0;
}

.top {
  width: 100%;
  height: 2.5rem;
  background: #303030;
}

.certificates {
  box-sizing: border-box;
  bottom: 0px;
  box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.12);
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 25px 5% 15px 5%;
  font-size: 16px;
  color: rgb(58, 58, 58);

  div {
    flex: 1;
  }

  .certificates-left {
    float: left;
    line-height: 40px;
  }

  .certificates-right {
    float: right;

    button {
      width: 100px;
      height: 40px;
      font-size: 18px;
      background-color: #1e90ff;
      color: #fff;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>
